<template>
  <!-- 学员管理页面-->
  <div class="auditManage" :class="{ EnClass: isEn }">
    <div class="auditManage-tool">
      <div class="auditManage-tool-item">
        <div class="auditManage-tool-item-title">
          {{ $t("el.schoolResourceManage.teacherName")
          }}{{ $t("el.symbol.colon") }}
        </div>
        <el-input
          class="auditManage-tool-item-option"
          v-model="form.staffName"
          :placeholder="$t('el.common.pleaseEnter')"
          size="small"
        ></el-input>
      </div>
      <div class="auditManage-tool-item">
        <div class="auditManage-tool-item-title">
          {{ $t("el.schoolResourceManage.workNumber")
          }}{{ $t("el.symbol.colon") }}
        </div>
        <el-input
          class="auditManage-tool-item-option"
          v-model="form.staffCode"
          :placeholder="$t('el.common.pleaseEnter')"
          size="small"
        ></el-input>
      </div>
      <!-- <div class="auditManage-tool-item">
        <div class="auditManage-tool-item-title">
          {{ $t("el.schoolResourceManage.school") }}{{ $t("el.symbol.colon") }}
        </div>
        <el-input
          class="auditManage-tool-item-option"
          v-model="form.schoolName"
          :placeholder="$t('el.common.pleaseEnter')"
          size="small"
        ></el-input>
      </div> -->
      <div class="auditManage-tool-item">
        <div class="auditManage-tool-item-title">
          {{ $t("el.schoolResourceManage.subjects")
          }}{{ $t("el.symbol.colon") }}
        </div>
        <el-select
          class="auditManage-tool-item-option"
          v-model="form.subjectId"
          :placeholder="$t('el.common.pleaseSelect')"
          size="small"
        >
          <el-option
            v-for="item in subjectOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </div>
      <div class="auditManage-tool-item">
        <div class="auditManage-tool-item-title">
          {{ $t("el.schoolResourceManage.grade") }}{{ $t("el.symbol.colon") }}
        </div>
        <el-select
          class="auditManage-tool-item-option"
          v-model="form.gradeId"
          :placeholder="$t('el.common.pleaseSelect')"
          size="small"
        >
          <el-option
            v-for="item in gradeOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </div>
      <div class="auditManage-tool-item">
        <div class="auditManage-tool-item-title">
          {{ $t("el.schoolResourceManage.scienceBooks")
          }}{{ $t("el.symbol.colon") }}
        </div>
        <el-input
          class="auditManage-tool-item-option"
          v-model="form.courseName"
          :placeholder="$t('el.common.pleaseEnter')"
          size="small"
        ></el-input>
      </div>
      <div class="auditManage-tool-item">
        <div class="auditManage-tool-item-title">
          {{ $t("el.schoolResourceManage.chapter") }}{{ $t("el.symbol.colon") }}
        </div>
        <el-input
          class="auditManage-tool-item-option"
          v-model="form.knowledgeName"
          :placeholder="$t('el.common.pleaseEnter')"
          size="small"
        ></el-input>
      </div>
      <div class="auditManage-tool-item">
        <div class="auditManage-tool-item-title">
          {{ $t("el.schoolResourceManage.auditStatus")
          }}{{ $t("el.symbol.colon") }}
        </div>
        <el-select
          class="auditManage-tool-item-option"
          v-model="form.reviewState"
          :placeholder="$t('el.common.pleaseSelect')"
          size="small"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="auditManage-tool-item">
        <div class="auditManage-tool-item-title">
          {{ $t("el.schoolResourceManage.auditor") }}{{ $t("el.symbol.colon") }}
        </div>
        <el-input
          class="auditManage-tool-item-option"
          v-model="form.reviewerName"
          :placeholder="$t('el.common.pleaseEnter')"
          size="small"
        ></el-input>
      </div>
      <div class="auditManage-tool-item">
        <div class="auditManage-tool-item-title">
          {{ $t("el.schoolResourceManage.auditDate")
          }}{{ $t("el.symbol.colon") }}
        </div>
        <el-date-picker
          class="auditManage-tool-item-option"
          size="small"
          v-model="form.date"
          type="daterange"
          :range-separator="$t('el.common.to')"
          :start-placeholder="$t('el.common.startDate')"
          :end-placeholder="$t('el.common.startDate')"
        >
        </el-date-picker>
      </div>
      <div class="auditManage-tool-item">
        <button class="btn-query" @click="getData">
          {{ $t("el.common.search") }}
        </button>
        <button class="btn-reset" @click="reset">
          {{ $t("el.common.reset") }}
        </button>
      </div>
    </div>
    <!-- 表格 -->
    <div class="auditManage-table">
      <el-table
        v-loading="loading"
        :data="tableData"
        border
        :header-cell-class-name="headerStyle"
        :cell-class-name="cellStyle"
        style="width: 100%"
      >
        <template slot="empty">
          <div style="padding-top: 111px; line-height: 0">
            <img
              src="@/assets/images/pic_empty_people@2x.png"
              alt=""
              style="width: 270px"
            />
          </div>
          <div
            style="
              color: rgba(100, 108, 149, 0.25);
              font-size: 18px;
              font-weight: 600;
              letter-spacing: 1px;
              padding-bottom: 120px;
            "
          >
            {{ $t("el.common.noData") }}
          </div>
        </template>
        <el-table-column
          prop="staffName"
          :label="$t('el.schoolResourceManage.teacherName')"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          prop="courseName"
          :label="$t('el.schoolResourceManage.scienceBooks')"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          prop="knowledgeName"
          :show-overflow-tooltip="true"
          :label="$t('el.schoolResourceManage.sectionName')"
        >
        </el-table-column>
        <el-table-column
          prop="resKjName"
          :label="$t('el.schoolResourceManage.resourceName')"
          min-width="180px"
        >
          <template slot-scope="scope">
            <div class="resource-col">
              <el-tooltip
                class="item"
                effect="dark"
                :content="scope.row.resJaName"
                placement="top"
              >
                <span class="resource-name">{{ scope.row.resJaName }}</span>
              </el-tooltip>
              <div style="vertical-align: middle">
                <el-button
                  type="text"
                  @click="handleLook(scope.row, 'resJaUrl')"
                  >{{ $t("el.common.view") }}</el-button
                >
                <el-button
                  type="text"
                  @click="exportFile(scope.row, 'resJaUrl')"
                  >{{ $t("el.common.download") }}</el-button
                >
              </div>
            </div>
            <div class="resource-col">
              <el-tooltip
                class="item"
                effect="dark"
                :content="scope.row.resKjName"
                placement="top"
              >
                <span class="resource-name">{{ scope.row.resKjName }}</span>
              </el-tooltip>
              <div style="vertical-align: middle">
                <el-button
                  type="text"
                  @click="handleLook(scope.row, 'resKjUrl')"
                  >{{ $t("el.common.view") }}</el-button
                >
                <el-button
                  type="text"
                  @click="exportFile(scope.row, 'resKjUrl')"
                  >{{ $t("el.common.download") }}</el-button
                >
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="reviewState"
          :label="$t('el.schoolResourceManage.auditStatus')"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <div>
              {{
                scope.row.reviewState == 0
                  ? $t("el.schoolResourceManage.toAudit")
                  : scope.row.reviewState == 1
                  ? $t("el.schoolResourceManage.returnToModify")
                  : $t("el.schoolResourceManage.approved")
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="reviewerName"
          :label="$t('el.schoolResourceManage.auditor')"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          prop="reviewTime"
          :label="$t('el.schoolResourceManage.auditTime')"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column min-width="130" :label="$t('el.common.operate')">
          <template slot-scope="scope">
            <div>
              <div>
                <el-button
                  type="text"
                  @click="handleAudit(scope.row)"
                  v-if="scope.row.reviewState == 0 && reviewAuth == 1"
                  >{{ $t("el.schoolResourceManage.audit") }}</el-button
                >
              </div>
              <div class="resource-col">
                <el-button type="text" @click="getAuditRecords(scope.row)">{{
                  $t("el.schoolResourceManage.auditRecords")
                }}</el-button>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--分页  -->
    <div class="auditManage-page" v-show="tableData.length > 0">
      <el-pagination
        layout="prev, pager, next"
        :current-page.sync="params.page"
        :page-size="params.limit"
        :total="total"
      >
      </el-pagination>
      <span class="el-pagination__total"
        >{{ $t("el.pagination.totalname") }}{{ Math.ceil(total / params.limit)
        }}{{ $t("el.pagination.pages") }} {{ total
        }}{{ $t("el.pagination.items") }}
      </span>
    </div>
    <ManageDialog
      v-if="dialogFormVisible"
      ref="contentConfig"
      @handleAuditSure="handleAuditSure"
    />
    <ManageOption v-if="dialogManage" ref="manageConfig" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  getCheckList,
  updateCheckState,
  queryAuditRecordsList,
} from "@/api/home/auditmanage";
import { getVersion } from "@/api/manage/compared-analyze";
import ManageDialog from "./manageDialog";
import ManageOption from "../components/manageOption.vue";
import gradeAndSubject from "@/mixin/gradeAndSubject.js";

export default {
  name: "auditManage",
  // 注册组件
  components: {
    ManageDialog,
    ManageOption,
  },
  mixins: [gradeAndSubject],
  // 定义数据
  data() {
    return {
      loading: false, // 列表加载状态
      tableData: [],
      form: {
        // 查询条件
        staffName: "",
        staffCode: "",
        // schoolName: "",
        subjectId: "",
        gradeId: "",
        courseName: "",
        knowledgeName: "",
        reviewState: null,
        reviewerName: "",
        date: "",
        // reviewTimeStart:'',
        // reviewTimeEnd:''
      },
      params: {
        // 分页
        limit: 10,
        offset: 0,
        page: 1,
      },
      total: 0,

      dialogFormVisible: false,
      dialogManage: false,
      gradeOptions: [], // 年级选项
      subjectOptions: [], // 科目选项
      isEn: false,
    };
  },
  // 计算属性
  computed: {
    ...mapGetters(["eduAuth", "reviewAuth"]),
    options() {
      return [
        {
          value: 0,
          label: this.$t("el.schoolResourceManage.toAudit"),
        },
        {
          value: 1,
          label: this.$t("el.schoolResourceManage.returnToModify"),
        },
        {
          value: 2,
          label: this.$t("el.schoolResourceManage.approved"),
        },
        {
          value: "",
          label: this.$t("el.schoolResourceManage.all"),
        },
      ];
    },
  },
  // 侦听器
  watch: {
    // 监听页数
    "params.page"(newVal, oldVal) {
      // 改变参数
      this.params.offset = this.params.limit * (this.params.page - 1);
      this.getData();
    },
    "$i18n.locale": {
      immediate: true,
      handler(val, old) {
        this.isEn = val === "en_US";
      },
    },
  },
  // 创建完成钩子
  created() {
    this.getData();
    this.optionInit();
  },
  // 挂在完成钩子
  mounted() {},
  // 方法定义
  methods: {
    headerStyle({ row, rowIndex }) {
      return "bskHeader";
    },
    cellStyle({ row, column, rowIndex, columnIndex }) {
      return "bskCell";
    },
    getData() {
      this.loading = true;
      let paramsData = {
        ...this.params,
        ...this.form,
      };
      delete paramsData["page"];
      delete paramsData["date"];
      if (this.form.date && this.form.date.length > 0) {
        paramsData.reviewTimeStart = this.$dateFormat(
          this.form.date[0],
          "yyyy-MM-dd HH:mm:ss"
        );
        paramsData.reviewTimeEnd = this.$dateFormat(
          this.form.date[1],
          "yyyy-MM-dd HH:mm:ss"
        );
      }
      getCheckList(paramsData)
        .then(({ data }) => {
          this.tableData = data.records;
          this.total = data.total;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    reset() {
      for (let item in this.form) {
        this.form[item] = "";
      }
      this.params.page = 1;
      this.params.reviewState = null;
      this.getData();
    },
    /**
     * 查看资源
     * @param {*} row
     */
    handleLook(row, type) {
      if (type === "resKjUrl") {
        getVersion({ resourceId: row.resKjId }).then((res) => {
          let version = res.data;
          let format = row.resKjUrl.substring(
            row.resKjUrl.lastIndexOf(".") + 1
          );
          if (
            ["ppt", "pptx"].indexOf(
              row.resKjUrl.substring(row.resKjUrl.lastIndexOf(".") + 1)
            ) !== -1
          ) {
            this.getwpsInit(
              row.resKjId,
              format,
              version,
              2,
              row.courseId,
              "KJ"
            );
          } else {
            this.getwpsInit(
              row.resKjId,
              format,
              version,
              1,
              row.courseId,
              "KJ"
            );
          }
        });
      }
      if (type === "resJaUrl") {
        getVersion({ resourceId: row.resJaId }).then((res) => {
          let version = res.data;
          let format = row.resJaUrl.substring(
            row.resJaUrl.lastIndexOf(".") + 1
          );
          // this.getwpsInit(row.resJaId, format, version, 1);
          if (
            ["doc", "docx"].indexOf(
              row.resJaUrl.substring(row.resJaUrl.lastIndexOf(".") + 1)
            ) !== -1
          ) {
            this.getwpsInit(
              row.resJaId,
              format,
              version,
              1,
              row.courseId,
              "JA"
            );
          } else {
            this.getwpsInit(
              row.resJaId,
              format,
              version,
              2,
              row.courseId,
              "JA"
            );
          }
        });
      }
      // let officePreviewUrl = this.$options.filters['officePreviewUrl'];
      // window.open(
      //   officePreviewUrl(row[type], this.getFormat(row[type]), false, true)
      // );
    },
    /**
     * 跳转到查看WPS页面
     */
    getwpsInit(id, format, version, types, courseId, resourceType) {
      this.$utils.openWindow(
        "lookWps?id=" +
          id +
          "&format=" +
          format +
          "&version=" +
          version +
          "&type=" +
          types +
          "&courseId=" +
          courseId +
          "&resourceType=" +
          resourceType
      );
    },
    /**
     *下载资源
     * @param {*} row
     */
    exportFile(row, type) {
      console.log(row);
      let resourceType = "";
      let resourceName = "";
      if (type === "resKjUrl") {
        resourceType = "kj";
        resourceName = row.resKjName;
      } else {
        resourceType = "ja";
        resourceName = row.resJaName;
      }
      // let queryParams = { fileName: resourceName, url: row[type] };
      let queryParams = {
        objectName: row[type],
        displayName: resourceName,
        isDownload: 1,
      };
      this.$utils.download("/obs/info/download", queryParams, {
        method: "get",
      });
    },
    /**
     * 获取文件格式
     * @param {*} url
     */
    getFormat(url) {
      let formatIndex = url.lastIndexOf(".");
      let format = "";
      if (formatIndex >= 0) {
        format = url.substring(formatIndex + 1);
      }
      return format;
    },
    /**
     * 审核
     * @param {*} row
     */
    handleAudit(row) {
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs.contentConfig && this.$refs.contentConfig.init(row);
      });
    },
    /**
     * 审核确认
     * @param {*} params
     */
    handleAuditSure(params) {
      updateCheckState(params)
        .then((data) => {
          this.$message.success(data.message);
          this.dialogFormVisible = false;
          this.reset();
        })
        .catch(() => {
          this.dialogFormVisible = false;
        });
    },
    /**
     * 审核记录
     * @param {*} params
     * operateType 0-跨校共享，1-审核通过，2-退回修改，3-审核通过并上线、4-上线，5-下线，6-加精，7-取消加精，8-撤回，9-提交审核
     */
    getAuditRecords(row) {
      this.dialogManage = true;
      queryAuditRecordsList({
        offset: 0,
        limit: 1000,
        packageId: row.packageId,
        operateType: [1, 2, 3, 8, 9],
        category: 0,
      }).then(({ data }) => {
        this.$nextTick(() => {
          this.$refs.manageConfig && this.$refs.manageConfig.init(data.records);
        });
      });
    },
    /**
     * 选项初始化
     */
    optionInit() {
      this.handleGetGradeAndSubject(); // mixin获取年级与科目
    },
  },
};
</script>

<style lang="less" scoped>
.auditManage {
  background: #fff;
  padding: 18px 0 46px 0;
  border-radius: 4px;
  &-tool {
    // height: 32px;
    line-height: 32px;
    margin: 20px 50px 0 51px;
    button {
      font-size: 12px;
      // font-family: "MicrosoftYaHei";
      color: rgba(249, 249, 249, 1);
      height: 32px;
      display: flex;
      background: @themeBlue;
      border-radius: 5px;
      float: left;
      justify-content: center;
      align-items: center;
      margin-left: 10px;
      cursor: pointer;
      outline: none;
    }
    .btn-reset,
    .btn-query {
      width: 65px;
    }
    .btn-query {
      margin-left: 0;
    }
    .btn-reset {
      background-color: #fff;
      color: #000;
      border: 1px solid #dcdfe6;
    }
    &-title {
      min-width: 120px;
      font-size: 14px;
      // font-family: "MicrosoftYaHei";
      color: rgba(70, 70, 70, 1);
    }
    &-btnicon {
      width: 14px;
      height: 14px;
      margin-right: 3px;
    }
    &-item-option {
      width: 180px;
    }
  }
  /deep/ .el-range-editor--small.el-input__inner {
    width: 350px;
  }
  &-table {
    padding: 0 50px 0 51px;
    /deep/.bskHeader {
      background-color: #f5f6f7 !important;
      color: #323232;
      text-align: center !important;
      font-size: 14px;
      // font-family: "MicrosoftYaHei";
      font-weight: normal;
      height: 50px;
      padding: 0;
      .cell {
        white-space: pre-line;
      }
    }
    /deep/.bskCell {
      text-align: center !important;
      color: #707276;
      font-size: 14px;
      height: 46px;
      padding: 0;
      .cell {
        line-height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        word-wrap: break-word;
        white-space: nowrap;
        display: block;
        div {
          width: 100%;
          line-height: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          word-wrap: break-word;
          white-space: nowrap;
          display: block;
        }
      }
    }
    /deep/.el-button--text {
      border: 0;
      color: @themeBlue;
    }
    /deep/ .el-table--enable-row-hover .el-table__body tr:hover > td {
      background: #f1f6ff;
    }
  }

  &-page {
    padding: 39px 50px 0 51px;
    text-align: center;
    /deep/ .el-pager li {
      height: 30px;
      line-height: 30px;
      width: 30px;
      min-width: 30px;
      border-radius: 50%;
      padding: 0 0px;
      margin: 0 5px;
      font-size: 13px;
      // font-family: "SourceHanSansCN-Medium", "SourceHanSansCN";
      font-weight: 400;
      color: rgba(40, 40, 40, 1);
    }
    /deep/ .el-pager li.active {
      background: @themeBlue;
      color: #fff;
      font-size: 13px;
      // font-family: "SourceHanSansCN-Medium", "SourceHanSansCN";
    }
    /deep/.el-pagination__total {
      font-size: 12px;
      // font-family: "MicrosoftYaHei";
      color: rgba(40, 40, 40, 1);
    }
    /deep/.el-pagination button {
      height: 30px;
      line-height: 30px;
    }
    /deep/ .el-pagination button:hover {
      color: @themeBlue;
    }
  }
  /deep/ .el-table__body .bskCell:nth-child(4) .cell {
    padding: 0;
  }
  /deep/ .el-table th.gutter {
    display: table-cell !important;
  }
  .resource-col {
    padding: 6px 10px;
    &:first-child {
      border-bottom: 1px solid #ebeef5;
    }
  }
  .resource-col /deep/ .el-button {
    padding: 8px 0 0;
  }
  .resource-name {
    display: inline-block;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    white-space: nowrap;
    vertical-align: middle;
  }
}
.auditManage-page {
  display: flex;
  align-items: center;
  margin: 0 auto;
  text-align: center;
  justify-content: center;
}
</style>
