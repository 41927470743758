<template>
  <el-dialog :visible.sync="visible" :title="title" width="500px" center>
    <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="80px">
      <el-form-item :label="$t('el.planCourse.state')" prop="reviewType">
        <el-radio-group v-model="ruleForm.reviewType">
          <el-radio class="radio" :label="1">{{
            $t("el.schoolResourceManage.approvedAndOnline")
          }}</el-radio>
          <el-radio class="radio" :label="2">{{
            $t("el.schoolResourceManage.approved")
          }}</el-radio>
          <el-radio class="radio" :label="3">{{
            $t("el.schoolResourceManage.returnToModify")
          }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item :label="$t('el.schoolResourceManage.note')" prop="context">
        <el-input
          type="textarea"
          v-model="ruleForm.context"
          placeholder=""
          rows="5"
          maxlength="500"
          style="max-width: 90%"
        ></el-input>
      </el-form-item>
      <div class="clear"></div>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button
        type="primary"
        @click="contentSubmit('ruleForm')"
        size="small"
        >{{ $t("el.common.confirm") }}</el-button
      >
      <el-button
        class="cancel"
        @click="closeContentDialog('ruleForm')"
        size="small"
        >{{ $t("el.common.cancel") }}</el-button
      >
    </div>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {
      title: this.$t("el.schoolResourceManage.audit"),
      visible: false,
      ruleForm: {
        id: "",
        reviewType: 1,
        context: null,
      },
      rules: {
        // context: [{ required: true, message: '请输入备注', trigger: 'blur' }],
        reviewType: [
          {
            required: true,
            message: this.$t("el.planCourse.SelectState"),
            trigger: "change",
          },
        ],
      },
    };
  },
  watch: {},
  computed: {},
  created() {},
  methods: {
    init(Info) {
      this.visible = true;
      this.ruleForm.id = Info.id;
      this.$nextTick(() => {
        this.$refs.ruleForm.resetFields();
      });
    },
    /**
     * 增加与编辑
     */
    contentSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.visible = false;
          this.$emit("handleAuditSure", this.ruleForm);
        } else {
          return false;
        }
      });
    },
    closeContentDialog(formName) {
      this.visible = false;
      this.$refs[formName].resetFields();
    },
  },
};
</script>
<style lang="less" scoped>
.clear {
  clear: both;
}
.cancel {
  margin-left: 40px;
}
/deep/.el-dialog__header {
  padding-top: 26px;
  padding-bottom: 0px;
}
.radio {
  margin-right: 12px;
}
/deep/ .el-radio__input.is-checked .el-radio__inner {
  border-color: #6049ff;
  background: #6049ff;
}
/deep/ .el-radio__input.is-checked + .el-radio__label {
  color: #6049ff;
}
</style>
